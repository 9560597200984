/*------------------------------------
  Slick
------------------------------------*/

[data-lazy] {
  opacity: 0;
  transition: opacity $transition-timing $transition-function;
}

.slick-cloned [data-scs-animation-in] {
  opacity: 0;
}

.dv-slick {
  // background: url(#{$root-path}svg/preloaders/circle-preloader.svg) no-repeat 50% 50%;

  .js-next,
  .js-prev {
    opacity: 0;
  }

  &.slick-initialized {
    background: none;

    .js-slide,
    .js-thumb {
      visibility: visible;
      height: auto;
      overflow: visible;
    }

    .js-next,
    .js-prev {
      opacity: 1;
    }
  }

  .js-slide,
  .js-thumb {
    height: 0;
    visibility: hidden;
    overflow: hidden;

    &:first-child {
      height: auto;
    }
  }
}
