

// ul {
//   list-style: none; /* Remove default bullets */
// }
ul li {
      line-height: 2;
}

ul li  {
  content: "";
}

.green-dots li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #00b7c3!important; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: .8em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

// body {
//   color:#77838f !important;
// }

.bg-green {
 background:  $primary-darker;
 background-color:  $primary-darker;
}


.line-height-title {
  line-height: 1.2em !important;
}

.transition-3d-hover {

box-shadow: 0 8px 16px 0 rgba(0,0,0,0.20);
@extend .shadow-lg;
}


.bg-creme {
background:#FDFAF1;
  background-color:#FDFAF1;

}


.owl-carousel .item {
  background: #FFFFFF;
  border: 1px solid #A7CDAF;
  border-radius: 8px;
  color: #84B48E;
  text-align: center;
}

.owl-carousel .item:hover {
  background: #84B48E;
  border: 1px solid #A7CDAF;
  border-radius: 8px;
  color: #FFFFFF;
  text-align: center;
  p { color:#fff;}
}


.card {
  background-color: #fdfaf1;

}

.card:hover {
  background: #FFFFFF;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.20);
  border-radius: 12px;
}

.hp-block {
  border: 1px solid rgba(192,192,180,0.30);
  border-radius: 20px;

  &:hover,
  &:focus {
    cursor: pointer;
@extend .shadow-lg;
}


}


.btn-primary {
  color: #fff;
    background-color: #e17641 !important;
    border-color: #e17641  !important;
    box-shadow: inset 0 1px 0 hsla(0,0%,100%,.15), 0 1px 1px rgba(0,0,0,.075);
    &:hover,
    &:focus {
   background-color: #d74c2f !important;
   border-color: #d74c2f  !important;
 }
}

.text-green {
  background: #A7CDAF;
  @extend .p-1;
  @extend .px-2;
  @extend .rounded;
  color:#fff!important;
}

@media screen and (max-width: 768px) {
    h1 {font-size:1.64em; }

    h2{ font-size:1.5em; }
}
